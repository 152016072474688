import type { GridFilterItem, GridFilterModel } from '@mui/x-data-grid-pro';

import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../common/store/store';
import { ManualOrdersFilterType } from './manual-orders';
import { ManualOrdersGridDefaults } from './manual-orders-grid-defaults';

export const initialState: ManualOrdersFilterType = {
  customerNames: ManualOrdersGridDefaults.customerNames,
  dir: ManualOrdersGridDefaults.dir,
  manualOrderTypes: ManualOrdersGridDefaults.manualOrderTypes,
  maxResults: ManualOrdersGridDefaults.maxResults,
  offset: ManualOrdersGridDefaults.offset,
  orderLineItemTypes: ManualOrdersGridDefaults.orderLineItemTypes,
  sort: ManualOrdersGridDefaults.sort,
  statuses: ManualOrdersGridDefaults.statuses
};

const manualOrdersFilterSlice = createSlice({
  initialState: initialState,
  name: 'manualOrdersFilter',
  reducers: {
    resetFilters: () => initialState,
    updateFilter: (_state, { payload }) => {
      return payload;
    },
    updateFilterModel: (
      state,
      { payload: model }: { payload: GridFilterModel }
    ) => {
      const customerNameFilterValues = model.items.reduce(
        (customerOptions: string[], filterItem: GridFilterItem) => {
          if (filterItem.field === 'customerName') {
            filterItem.value?.forEach((value: string) => {
              if (!customerOptions.includes(value)) {
                customerOptions.push(value);
              }
            });
          }

          return customerOptions;
        },
        []
      );

      const orderLineItemTypeFilterValues = model.items.reduce(
        (orderLineItemTypeOptions: string[], filterItem: GridFilterItem) => {
          if (filterItem.field === 'orderLineItemType') {
            filterItem.value.forEach((value: string) => {
              if (!orderLineItemTypeOptions.includes(value)) {
                orderLineItemTypeOptions.push(value);
              }
            });
          }

          return orderLineItemTypeOptions;
        },
        []
      );

      const statusFilterValues = model.items.reduce(
        (statusOptions: string[], filterItem: GridFilterItem) => {
          if (filterItem.field === 'status') {
            filterItem.value.forEach((value: string) => {
              if (!statusOptions.includes(value)) {
                statusOptions.push(value);
              }
            });
          }

          return statusOptions;
        },
        []
      );

      return {
        ...state,
        customerNames: customerNameFilterValues,
        domainNames: model.quickFilterValues
          ? model.quickFilterValues?.join(' ')
          : ManualOrdersGridDefaults.domainNames,
        orderLineItemTypes: orderLineItemTypeFilterValues,
        statuses: statusFilterValues
      };
    }
  }
});

export const manualOrdersFilterReducer = manualOrdersFilterSlice.reducer;

export const { resetFilters, updateFilter, updateFilterModel } =
  manualOrdersFilterSlice.actions;

export const selectManualOrderFilters = (state: RootState) =>
  state.manualOrdersFilter;

export const selectCurrentPage = (state: RootState) =>
  state.manualOrdersFilter.offset / state.manualOrdersFilter.maxResults;
