import { Box, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ManualOrderEntryApiResponse,
  manualOrderStatusFieldNames
} from '../manual-orders';
import { useUpdateManualOrdersMutation } from '../manual-orders-api-slice';
import {
  selectSelectedManualOrders,
  setIsSubmittingManualOrders
} from '../manual-orders-slice';
import { ManualOrderStatusSnackbar } from './ManualOrderStatusSnackbar';

type ManualOrderStatusExpirationDateProps = {
  expirationDateRequired: (orderLineItemTypeLabel: string) => boolean;
  selectedContainsCompleted: boolean;
};

export const ManualOrderStatusExpirationDate = ({
  expirationDateRequired,
  selectedContainsCompleted
}: ManualOrderStatusExpirationDateProps) => {
  const selectedManualOrders = useSelector(selectSelectedManualOrders);

  const dispatch = useDispatch();

  const [displayedExpirationDate, setDisplayedExpirationDate] = useState<
    null | string
  >(null);

  useEffect(() => {
    const moreThanOneSelected = (selectedManualOrders?.length ?? 0) > 1;
    const oneSelected = (selectedManualOrders?.length ?? 0) === 1;

    if (moreThanOneSelected && displayedExpirationDate !== null) {
      setDisplayedExpirationDate(null);
    } else if (oneSelected) {
      setDisplayedExpirationDate(
        selectedManualOrders![0].expirationDate ?? null
      );
    } else if (displayedExpirationDate !== null) {
      setDisplayedExpirationDate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedManualOrders]);

  const [updateManualOrders, { isError, isSuccess, reset }] =
    useUpdateManualOrdersMutation();

  const handleExpirationDateChange = (value: Dayjs | null) => {
    dispatch(setIsSubmittingManualOrders(true));

    updateManualOrders({
      manualOrders: selectedManualOrders!.map(
        (manualOrder: ManualOrderEntryApiResponse) => ({
          ...manualOrder,
          expirationDate: expirationDateRequired(
            manualOrder.$orderLineItemType.label
          )
            ? value!.toISOString()
            : undefined
        })
      )
    });
  };

  const getExpirationDateTooltipTitle = () => {
    if (selectedContainsCompleted) {
      return 'One or more selected manual orders are complete and their expiration dates cannot be updated.';
    } else {
      return null;
    }
  };

  return (
    <>
      <ManualOrderStatusSnackbar
        fieldName={manualOrderStatusFieldNames.status}
        isError={isError}
        isSuccess={isSuccess}
        reset={reset}
      />
      <Tooltip arrow followCursor title={getExpirationDateTooltipTitle()}>
        <Box>
          <DatePicker
            disabled={selectedContainsCompleted}
            label="Expiration Date"
            onChange={handleExpirationDateChange}
            slotProps={{
              textField: {
                error: false,
                fullWidth: true,
                size: 'small'
              }
            }}
            value={dayjs(displayedExpirationDate)}
          />
        </Box>
      </Tooltip>
    </>
  );
};
