import type PopupState from 'material-ui-popup-state';

import { faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import React from 'react';

import { ContactSetsDialog } from '../../../common/components/contact-sets/ContactSetsDialog';

type ManualOrdersOrderInformationWorkItemsProps = {
  workItems?: {
    [key: string]: string;
  };
};

export const ManualOrdersOrderInformationWorkItems = ({
  workItems = {}
}: ManualOrdersOrderInformationWorkItemsProps) => {
  const theme = useTheme();

  return (
    <>
      <Divider sx={{ my: 1 }} />
      {Object.entries(workItems)
        .filter(value => !value[0].includes('Contact Set ID'))
        .map(([key, value]) => {
          return (
            <Grid
              item
              key={`${value.replaceAll(' ', '-')}-key-value-pair`}
              xs={12}
            >
              <Typography
                color={theme.palette.text.secondary}
                variant="caption"
              >
                {key}
              </Typography>
              <Box sx={{ alignItems: 'center', display: 'flex' }}>
                <Typography
                  sx={{
                    flexShrink: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'pre-line'
                  }}
                  title={value}
                  variant="body1"
                >
                  {value}
                </Typography>
                {key.includes('Contact Set') && (
                  <ContactSetsDialog
                    contactSetId={+workItems[key + ' ID']}
                    domains={[]}
                    lockAutocomplete={true}
                    renderActions={({
                      bindToggle,
                      dialogState
                    }: {
                      bindToggle: (dialogState: typeof PopupState) => {
                        onClick: (event: React.MouseEvent) => void;
                        onTouchStart: (event: React.TouchEvent) => void;
                      };
                      dialogState: typeof PopupState;
                    }) => (
                      <Box sx={{ width: '100%' }}>
                        <Button
                          sx={{ mb: 1, ml: 2 }}
                          variant="outlined"
                          {...bindToggle(dialogState)}
                        >
                          Cancel
                        </Button>
                      </Box>
                    )}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    renderTrigger={(props: any) => (
                      <IconButton
                        {...props}
                        size="small"
                        sx={{
                          '&:hover': {
                            background: 'none'
                          },
                          color: theme.palette.primary.main,
                          flexShrink: 0,
                          mx: 1,
                          padding: 0
                        }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </IconButton>
                    )}
                  />
                )}
              </Box>
            </Grid>
          );
        })}
    </>
  );
};
