import { Box, Tooltip } from '@mui/material';
import React from 'react';

type TextCellWithOverflowProps = {
  cellValue: null | string | undefined;
};

export const TextCellWithOverflow = ({
  cellValue
}: TextCellWithOverflowProps) => {
  return (
    <Tooltip arrow title={cellValue}>
      <Box
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whitespace: 'nowrap'
        }}
      >
        {cellValue}
      </Box>
    </Tooltip>
  );
};
