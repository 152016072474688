import titleCase from 'string-title-case';

import { MANUAL_ORDERS_TAG, apiSlice } from '../../common/store/api-slice';
import { getLocalizedDate } from '../../common/utils/date-time';
import {
  ManualOrderApiResponse,
  ManualOrderEntryApiResponse
} from './manual-orders';

const manualOrdersApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    exportManualOrders: builder.mutation({
      query: ({
        accountNames,
        dir,
        domainNames,
        manualOrderTypes,
        maxResults,
        offset,
        orderLineItemTypes,
        reseller,
        sort,
        statuses
      }) => ({
        method: 'GET',
        url:
          `resellers/${reseller}/manual-order/lineitems/csv` +
          `?dir=${dir}&maxResults=${maxResults}&offset=${offset}&sort=${sort}` +
          '&domainName=' +
          (domainNames?.replaceAll(' ', '+') ?? '') +
          (accountNames
            ?.map((accountName: string) => `&accountName=${accountName}`)
            ?.join('') ?? '') +
          (manualOrderTypes
            ?.map((type: string) => `&manualOrderTypes=${type}`)
            ?.join('') ?? '') +
          (orderLineItemTypes
            ?.map((type: string) => `&orderLineItemType=${type}`)
            ?.join('') ?? '') +
          (statuses?.map((status: string) => `&status=${status}`)?.join('') ??
            '')
      })
    }),
    getOrderLineItemTypes: builder.query({
      query: ({ manualOrderTypes, reseller }) =>
        `resellers/${reseller}/orders/order-types?` +
        manualOrderTypes
          ?.map((type: string) => `manualOrderTypes=${type}&`)
          .join(''),
      transformResponse: (response: string[]) =>
        response?.map((type: string) => ({
          label: titleCase(type.replaceAll('_', ' ')),
          value: type
        }))
    }),
    getPageOfManualOrders: builder.query({
      providesTags: [MANUAL_ORDERS_TAG],
      query: ({
        accountNames,
        dir,
        domainNames,
        manualOrderTypes,
        maxResults,
        offset,
        orderLineItemTypes,
        reseller,
        sort,
        statuses
      }) =>
        `resellers/${reseller}/manual-order` +
        `?dir=${dir}&maxResults=${maxResults}&offset=${offset}&sort=${sort}` +
        '&domainName=' +
        (domainNames?.replaceAll(' ', '+') ?? '') +
        (accountNames
          ?.map((accountName: string) => `&accountName=${accountName}`)
          ?.join('') ?? '') +
        (manualOrderTypes
          ?.map((type: string) => `&manualOrderTypes=${type}`)
          ?.join('') ?? '') +
        (orderLineItemTypes
          ?.map((type: string) => `&orderLineItemType=${type}`)
          ?.join('') ?? '') +
        (statuses?.map((status: string) => `&status=${status}`)?.join('') ??
          ''),
      transformResponse: (response: ManualOrderApiResponse) => ({
        ...response,
        entries: response.entries.map((entry: ManualOrderEntryApiResponse) => {
          entry.$orderLineItemType = {
            label: titleCase(entry.orderLineItemType.replaceAll('_', ' ')),
            value: entry.orderLineItemType
          };

          entry.$status = {
            label: titleCase(entry.status.replaceAll('_', ' ')),
            value: entry.status
          };

          entry.$createdDateFormatted = getLocalizedDate(entry.createdDate);

          if (entry.completedDate) {
            entry.$completedDateFormatted = getLocalizedDate(
              entry.completedDate
            );
          }

          entry.$price = `$${entry.price}`;

          return entry;
        })
      })
    }),
    updateManualOrders: builder.mutation({
      invalidatesTags: [MANUAL_ORDERS_TAG],
      query: ({ manualOrders, reseller }) => ({
        body: manualOrders,
        method: 'PUT',
        url: `resellers/${reseller}/manual-order`
      })
    })
  })
});

export const {
  useExportManualOrdersMutation,
  useGetOrderLineItemTypesQuery,
  useGetPageOfManualOrdersQuery,
  useUpdateManualOrdersMutation
} = manualOrdersApiSlice;
