const getNoneSelectedMessage = (tabName: string) =>
  `Select a manual order to view ${tabName}.`;

export const manualOrdersTabs = Object.freeze({
  NOTES: {
    label: 'Notes',
    multipleSelectedMessage: 'Select a single manual order to view notes.',
    noneSelectedMessage: getNoneSelectedMessage('notes'),
    value: 'notes'
  },
  ORDER_INFORMATION: {
    label: 'Order Information',
    noneSelectedMessage: getNoneSelectedMessage('order information'),
    value: 'orderInformation'
  },
  STATUS: {
    label: 'Status',
    noneSelectedMessage: getNoneSelectedMessage('status'),
    value: 'status'
  }
});
