import { Box, Paper, Tab, Tabs } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectReseller } from '../../common/store/reseller';
import { ManualOrdersDetails } from './ManualOrdersDetails';
import { ManualOrdersTable } from './ManualOrdersTable';
import { useGetPageOfManualOrdersQuery } from './manual-orders-api-slice';
import {
  resetFilters,
  selectManualOrderFilters
} from './manual-orders-filter-slice';

export const ManualOrders = () => {
  const dispatch = useDispatch();

  const reseller = useSelector(selectReseller);
  const filterOptions = useSelector(selectManualOrderFilters);

  const { data, isFetching, isLoading, isUninitialized } =
    useGetPageOfManualOrdersQuery(
      {
        accountNames: filterOptions.customerNames,
        dir: filterOptions.dir,
        domainNames: filterOptions.domainNames,
        manualOrderTypes: filterOptions.manualOrderTypes,
        maxResults: filterOptions.maxResults,
        offset: filterOptions.offset,
        orderLineItemTypes: filterOptions.orderLineItemTypes,
        reseller: reseller,
        sort: filterOptions.sort,
        statuses: filterOptions.statuses
      },
      { refetchOnMountOrArgChange: true, skip: !reseller }
    );

  // reset filters on mount/unmount
  useEffect(() => {
    dispatch(resetFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <Tabs sx={{ pl: 3 }} value={0}>
        <Tab label="Manual Orders" />
      </Tabs>
      <Grid
        container
        spacing={0}
        sx={{ flexGrow: 1, height: 'calc(100% - 48px)' }}
      >
        <Grid sx={{ height: '100%' }} xs={8}>
          <Paper sx={{ height: '100%' }}>
            <ManualOrdersTable
              isLoading={isLoading || isUninitialized || isFetching}
              manualOrdersResponse={data}
            />
          </Paper>
        </Grid>
        <Grid sx={{ height: '100%' }} xs={4}>
          <ManualOrdersDetails />
        </Grid>
      </Grid>
    </Box>
  );
};
