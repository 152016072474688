import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import React, { useState } from 'react';

type ManualOrderStatusCompleteConfirmDialogProps = {
  cancelDialog: () => void;
  handleSubmit: () => Promise<never>;
  isOpen: boolean;
};

export const ManualOrderStatusCompleteConfirmDialog = ({
  cancelDialog,
  handleSubmit,
  isOpen
}: ManualOrderStatusCompleteConfirmDialogProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitClicked = () => {
    setIsSubmitting(true);

    handleSubmit()
      .then(() => cancelDialog())
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Dialog onClose={cancelDialog} open={isOpen}>
      <DialogTitle>Are you sure you want to complete this order?</DialogTitle>
      <DialogContent>
        Completing this order will update the selected domain(s) or service
        orders. This action cannot be easily undone. Are you sure you want to
        proceed?
      </DialogContent>
      <DialogActions sx={{ p: 4, pb: 2 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            width: '100%'
          }}
        >
          <Button
            onClick={() => cancelDialog()}
            sx={{ mr: 1 }}
            variant="outlined"
          >
            No
          </Button>
          <LoadingButton
            loading={isSubmitting}
            onClick={handleSubmitClicked}
            variant="contained"
          >
            Yes
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
