import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setIsSubmittingManualOrders } from '../manual-orders-slice';

type ManualOrderStatusSnackbarProps = {
  fieldName: string;
  isError: boolean;
  isSuccess: boolean;
  reset: () => void;
};

export const ManualOrderStatusSnackbar = ({
  fieldName,
  isError,
  isSuccess,
  reset
}: ManualOrderStatusSnackbarProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(`Successfully updated ${fieldName}`, {
        variant: 'success'
      });

      dispatch(setIsSubmittingManualOrders(false));

      reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(
        `Failed to update ${fieldName}. Please try again later or contact support.`,
        {
          variant: 'error'
        }
      );

      dispatch(setIsSubmittingManualOrders(false));

      reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return null;
};
