import { apiSlice } from './api-slice';

export const dnssecApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDnssecOptionsByDomainName: builder.query({
      query: ({ customerName, domainName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/dnssec/${domainName}`
    })
  })
});

export const { useGetDnssecOptionsByDomainNameQuery } = dnssecApiSlice;
