import { Backdrop, CircularProgress, Divider, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TableDetailsHeader } from '../../../common/components/table-details-header/TableDetailsHeader';
import {
  ManualOrderEntryApiResponse,
  ManualOrderStatusOptions
} from '../manual-orders';
import {
  selectIsSubmittingManualOrders,
  selectSelectedManualOrders
} from '../manual-orders-slice';
import { manualOrdersTabs } from '../manual-orders-tabs';
import { ManualOrderStatusAutocomplete } from './ManualOrderStatusAutocomplete';
import { ManualOrderStatusCompletedDate } from './ManualOrderStatusCompletedDate';
import { ManualOrderStatusExpirationDate } from './ManualOrderStatusExpirationDate';

export const ManualOrderStatus = () => {
  const selectedManualOrders = useSelector(selectSelectedManualOrders);
  const isSubmittingManualOrders = useSelector(selectIsSubmittingManualOrders);

  const [selectedContainsCompleted, setSelectedContainsCompleted] =
    useState(false);

  // disable status autocomplete selected contains a completed item
  useEffect(() => {
    const noneSelected = (selectedManualOrders?.length ?? 0) < 1;

    if (noneSelected && selectedContainsCompleted) {
      setSelectedContainsCompleted(false);
    } else if (!noneSelected) {
      const completedFound = selectedManualOrders!.some(
        (value: ManualOrderEntryApiResponse) =>
          value.status === ManualOrderStatusOptions.COMPLETE
      );

      if (completedFound && !selectedContainsCompleted) {
        setSelectedContainsCompleted(true);
      } else if (!completedFound && selectedContainsCompleted) {
        setSelectedContainsCompleted(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedManualOrders]);

  const getNoneSelectedText = () => {
    if ((selectedManualOrders?.length ?? 0) < 1) {
      return manualOrdersTabs.STATUS.noneSelectedMessage;
    } else {
      return null;
    }
  };

  const expirationDateRequiredForOrderLineItemType = (
    orderLineItemTypeFormatted: string
  ) => {
    return [
      'DTSA', // Domain Transfer Success Add
      'Domain Auto Renewal',
      'Domain Registration',
      'Domain Renewal'
    ].includes(orderLineItemTypeFormatted);
  };

  return (
    <>
      <TableDetailsHeader
        headerText={manualOrdersTabs.STATUS.label}
        noneSelectedText={getNoneSelectedText()}
      />
      <Backdrop
        open={isSubmittingManualOrders}
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          position: 'absolute',
          zIndex: theme => theme.zIndex.drawer + 1
        }}
      >
        <CircularProgress />
      </Backdrop>
      {getNoneSelectedText() === null && (
        <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
          <ManualOrderStatusAutocomplete
            selectedContainsCompleted={selectedContainsCompleted}
          />
          <Divider sx={{ my: 1 }} />
          <ManualOrderStatusCompletedDate
            expirationDateRequired={expirationDateRequiredForOrderLineItemType}
            selectedContainsCompleted={selectedContainsCompleted}
          />
          {selectedManualOrders?.some(manualOrder =>
            expirationDateRequiredForOrderLineItemType(
              manualOrder.$orderLineItemType.label
            )
          ) && (
            <ManualOrderStatusExpirationDate
              expirationDateRequired={
                expirationDateRequiredForOrderLineItemType
              }
              selectedContainsCompleted={selectedContainsCompleted}
            />
          )}
        </Stack>
      )}
    </>
  );
};
