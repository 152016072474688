import React from 'react';
import { useSelector } from 'react-redux';

import { Notes } from '../../../common/components/notes/Notes';
import { noteEntityType } from '../../../common/components/notes/note-entity-type';
import { selectSelectedManualOrders } from '../manual-orders-slice';
import { manualOrdersTabs } from '../manual-orders-tabs';

export const ManualOrderNotes = () => {
  const selectedManualOrders = useSelector(selectSelectedManualOrders);

  return (
    <Notes
      detailsTab={manualOrdersTabs.NOTES}
      entityType={noteEntityType.manualOrder}
      selectedDomains={selectedManualOrders}
    />
  );
};
