import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectReseller } from '../../common/store/reseller';
import { useExportManualOrdersMutation } from './manual-orders-api-slice';
import { selectManualOrderFilters } from './manual-orders-filter-slice';

export const ManualOrdersExportButton = () => {
  const reseller = useSelector(selectReseller);

  const filterOptions = useSelector(selectManualOrderFilters);

  const [
    exportManualOrders,
    {
      isError: isExportError,
      isLoading: isExportLoading,
      isSuccess: isExportSuccess
    }
  ] = useExportManualOrdersMutation();

  useEffect(() => {
    if (isExportSuccess) {
      enqueueSnackbar(
        'Your export has been started. You will receive an email with the results shortly.',
        {
          variant: 'success'
        }
      );
    }
  }, [isExportSuccess]);

  useEffect(() => {
    if (isExportError) {
      enqueueSnackbar(
        'Failed to request export. Please try again later or contact support',
        {
          variant: 'error'
        }
      );
    }
  }, [isExportError]);

  const handleExport = () => {
    exportManualOrders({
      accountNames: filterOptions.customerNames,
      dir: filterOptions.dir,
      domainNames: filterOptions.domainNames,
      manualOrderTypes: filterOptions.manualOrderTypes,
      maxResults: filterOptions.maxResults,
      offset: filterOptions.offset,
      orderLineItemTypes: filterOptions.orderLineItemTypes,
      reseller: reseller,
      sort: filterOptions.sort,
      statuses: filterOptions.statuses
    });
  };

  return (
    <LoadingButton
      loading={isExportLoading}
      onClick={handleExport}
      size="small"
      startIcon={<SaveAltIcon />}
    >
      Export
    </LoadingButton>
  );
};
