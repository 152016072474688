import titleCase from 'string-title-case';

export enum ManualOrderTypes {
  DNS_DOMAIN = 'DNS_DOMAIN',
  SERVICE_ITEM = 'SERVICE_ITEM',
  WEB3_REGISTRATION = 'WEB3_REGISTRATION'
}

export enum ManualOrderStatusOptions {
  CLOSED = 'CLOSED',
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED'
}

export const manualOrderStatusFieldNames = Object.freeze({
  completedDate: 'completed date',
  expirationDate: 'expiration date',
  status: 'status'
});

export const manualOrderStatusFilterValueOptions = [
  {
    label: titleCase(ManualOrderStatusOptions.CLOSED),
    value: ManualOrderStatusOptions.CLOSED
  },
  {
    label: titleCase(ManualOrderStatusOptions.COMPLETE),
    value: ManualOrderStatusOptions.COMPLETE
  },
  {
    label: titleCase(ManualOrderStatusOptions.IN_PROGRESS.replaceAll('_', ' ')),
    value: ManualOrderStatusOptions.IN_PROGRESS
  },
  {
    label: titleCase(ManualOrderStatusOptions.PENDING),
    value: ManualOrderStatusOptions.PENDING
  },
  {
    label: titleCase(ManualOrderStatusOptions.SUBMITTED),
    value: ManualOrderStatusOptions.SUBMITTED
  }
];

export type ManualOrderApiResponse = {
  $customerOptions: string[];
  $orderLineItemTypeOptions: string[];
  $statusOptions: string[];
  entries: ManualOrderEntryApiResponse[];
  maxResults: number;
  offset: number;
  pages: number;
  totalCount: number;
};

export type ManualOrderEntryApiResponse = {
  $completedDateFormatted: string;
  $createdDateFormatted: string;
  $orderLineItemType: {
    label: string;
    value: string;
  };
  $price: string;
  $status: {
    label: string;
    value: string;
  };
  billableUnits: number;
  completedDate?: string | undefined;
  createdDate: string;
  customerName: string;
  domainName: string;
  expirationDate?: string | undefined;
  id: number;
  orderLineItemType: string;
  price: number;
  status: string;
  unitOfMeasure: string;
  unitPrice: number;
  workItems: {
    [key: string]: string;
  };
};

export type ManualOrdersFilterType = {
  customerNames?: string[] | undefined;
  dir: string;
  domainNames?: string | undefined;
  manualOrderTypes: ManualOrderTypes[];
  maxResults: number;
  offset: number;
  orderLineItemTypes: string[];
  search?: string | undefined;
  sort: string;
  statuses: string[];
};
