import { faCheck, faClock, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material';
import React from 'react';

import { LINE_ITEM_STATUSES } from '../../../../common/store/job-response-api-slice';

export const PurchaseStatus = ({ domainObject, jobDomainStatus }) => {
  const theme = useTheme();
  let status = LINE_ITEM_STATUSES.pending;
  const isManual =
    domainObject.$tldObject.manual ||
    domainObject.$tldObject.forceManualRegistration;

  if (!isManual && jobDomainStatus.length > 0) {
    status = jobDomainStatus.find(
      domain => domain.domain === domainObject.domainName
    ).status;
  }
  if (isManual) {
    return (
      <FontAwesomeIcon
        data-testid="fa-check-manual"
        icon={faCheck}
        size="lg"
        style={{ color: theme.palette.info.main, width: 25 }}
      />
    );
  } else if (status === LINE_ITEM_STATUSES.pending) {
    return (
      <FontAwesomeIcon
        data-testid="fa-clock"
        icon={faClock}
        size="sm"
        style={{ color: theme.palette.warning.main, width: 25 }}
      />
    );
  } else if (status === LINE_ITEM_STATUSES.success) {
    return (
      <FontAwesomeIcon
        data-testid="fa-check"
        icon={faCheck}
        size="lg"
        style={{ color: theme.palette.success.main, width: 25 }}
      />
    );
  } else {
    return (
      <FontAwesomeIcon
        data-testid="fa-xmark"
        icon={faXmark}
        size="lg"
        style={{ color: theme.palette.error.main, width: 25 }}
      />
    );
  }
};
