import type { ButtonProps } from '@mui/material';

import {
  faBuilding,
  faCalendar,
  faDollar
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Grid, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  CLIPBOARD_BUTTON_TYPES,
  CopyToClipboard
} from '../../../common/components/copy-to-clipboard/CopyToClipboard';
import { TableDetailsHeader } from '../../../common/components/table-details-header/TableDetailsHeader';
import { selectSelectedManualOrders } from '../manual-orders-slice';
import { manualOrdersTabs } from '../manual-orders-tabs';
import { ManualOrdersOrderInformationWorkItems } from './ManualOrdersOrderInformationWorkItems';

/**
 * Since the <CopyToClipboard /> component
 * is written in JSX, we have to explicitly
 * add types the component props here
 */
type CopyToClipboardProps =
  | {
      buttonLabel?: string;
      buttonType?: string;
      textToCopy?: string;
    }
  | ButtonProps;

const TypedCopyToClipboard = CopyToClipboard as React.FC<CopyToClipboardProps>;

export const ManualOrdersOrderInformation = () => {
  const theme = useTheme();

  const selectedManualOrders = useSelector(selectSelectedManualOrders);

  const getNoneSelectedText = () => {
    if ((selectedManualOrders?.length ?? 0) < 1) {
      return manualOrdersTabs.ORDER_INFORMATION.noneSelectedMessage;
    } else if ((selectedManualOrders?.length ?? 0) > 1) {
      return 'Select a single manual order to view order information.';
    } else {
      return null;
    }
  };

  return (
    <>
      <TableDetailsHeader
        headerText={manualOrdersTabs.ORDER_INFORMATION.label}
        noneSelectedText={getNoneSelectedText()}
      />
      {getNoneSelectedText() === null && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Typography
              sx={{
                flexShrink: 1,
                minWidth: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              title={selectedManualOrders?.at(0)?.domainName}
              variant="h6"
            >
              {selectedManualOrders?.at(0)?.domainName}
            </Typography>
            <TypedCopyToClipboard
              buttonType={CLIPBOARD_BUTTON_TYPES.iconButton}
              sx={{
                '&:hover': {
                  background: 'none'
                },
                color: theme.palette.primary.main,
                flexShrink: 0,
                mx: 1,
                padding: 0
              }}
              textToCopy={selectedManualOrders?.at(0)?.domainName}
            />
          </Box>
          <Stack alignItems="center" direction="row" gap={1}>
            <Typography
              sx={{ color: theme.palette.text.secondary }}
              variant="body2"
            >
              {selectedManualOrders?.at(0)?.$orderLineItemType?.label}
            </Typography>
          </Stack>
          <Divider sx={{ my: 1 }} />
          <Grid container spacing={2}>
            {/* Order Creation Date */}
            <Grid item xs={6}>
              <Box>
                <FontAwesomeIcon
                  color={theme.palette.text.secondary}
                  icon={faCalendar}
                />
                <Typography
                  color={theme.palette.text.secondary}
                  sx={{ ml: 1 }}
                  variant="caption"
                >
                  Order Creation Date
                </Typography>
              </Box>
              <Typography variant="body1">
                {selectedManualOrders?.at(0)?.$createdDateFormatted}
              </Typography>
            </Grid>
            {/* Order Completed Date */}
            <Grid item xs={6}>
              <Box>
                <FontAwesomeIcon
                  color={theme.palette.text.secondary}
                  icon={faCalendar}
                />
                <Typography
                  color={theme.palette.text.secondary}
                  sx={{ ml: 1 }}
                  variant="caption"
                >
                  Order Completion Date
                </Typography>
              </Box>
              <Typography variant="body1">
                {selectedManualOrders?.at(0)?.$completedDateFormatted ?? '-'}
              </Typography>
            </Grid>
            {/* Customer Name */}
            <Grid item xs={6}>
              <Box>
                <FontAwesomeIcon
                  color={theme.palette.text.secondary}
                  icon={faBuilding}
                />
                <Typography
                  color={theme.palette.text.secondary}
                  sx={{ ml: 1 }}
                  variant="caption"
                >
                  Customer Name
                </Typography>
              </Box>
              <Typography variant="body1">
                {selectedManualOrders?.at(0)?.customerName}
              </Typography>
            </Grid>
            {/* Price */}
            <Grid item xs={6}>
              <Box>
                <FontAwesomeIcon
                  color={theme.palette.text.secondary}
                  icon={faDollar}
                />
                <Typography
                  color={theme.palette.text.secondary}
                  sx={{ ml: 1 }}
                  variant="caption"
                >
                  Total Price
                </Typography>
              </Box>
              <Typography variant="body1">
                {selectedManualOrders?.at(0)?.$price ?? '-'}
              </Typography>
            </Grid>
          </Grid>
          {/* Work Items */}
          <ManualOrdersOrderInformationWorkItems
            workItems={selectedManualOrders?.at(0)?.workItems}
          />
        </>
      )}
    </>
  );
};
