import {
  faCircleInfo,
  faClipboardQuestion,
  faNoteSticky
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';

import { TabWithTooltip } from '../../common/components/tabs/TabWithTooltip';
import { tabPanelStyles } from '../../common/components/tabs/tab-panel-styles';
import { manualOrdersTabs } from './manual-orders-tabs';
import { ManualOrderNotes } from './notes/ManualOrderNotes';
import { ManualOrdersOrderInformation } from './order-information/ManualOrdersOrderInformation';
import { ManualOrderStatus } from './order-status/ManualOrderStatus';

export const ManualOrdersDetails = () => {
  const theme = useTheme();

  const [tabValue, setTabValue] = useState(
    manualOrdersTabs.ORDER_INFORMATION.value
  );

  const handleTabChange = (_event: unknown, newValue: string) =>
    setTabValue(newValue);

  return (
    <Stack direction="row" sx={{ height: '100%' }}>
      <TabContext value={tabValue}>
        <TabList
          aria-label="Manual Orders Tabs"
          onChange={handleTabChange}
          orientation="vertical"
          sx={{ flexShrink: 0, width: '90px' }}
        >
          <TabWithTooltip
            aria-label={manualOrdersTabs.ORDER_INFORMATION.label}
            icon={<FontAwesomeIcon icon={faCircleInfo} />}
            title={manualOrdersTabs.ORDER_INFORMATION.label}
            value={manualOrdersTabs.ORDER_INFORMATION.value}
          />
          <TabWithTooltip
            aria-label={manualOrdersTabs.STATUS.label}
            icon={<FontAwesomeIcon icon={faClipboardQuestion} />}
            title={manualOrdersTabs.STATUS.label}
            value={manualOrdersTabs.STATUS.value}
          />
          <TabWithTooltip
            aria-label={manualOrdersTabs.NOTES.label}
            icon={<FontAwesomeIcon icon={faNoteSticky} />}
            title={manualOrdersTabs.NOTES.label}
            value={manualOrdersTabs.NOTES.value}
          />
        </TabList>
        {tabValue === manualOrdersTabs.ORDER_INFORMATION.value && (
          <TabPanel
            sx={tabPanelStyles({ theme })}
            value={manualOrdersTabs.ORDER_INFORMATION.value}
          >
            <ManualOrdersOrderInformation />
          </TabPanel>
        )}
        {tabValue === manualOrdersTabs.STATUS.value && (
          <TabPanel
            sx={tabPanelStyles({ theme })}
            value={manualOrdersTabs.STATUS.value}
          >
            <ManualOrderStatus />
          </TabPanel>
        )}
        {tabValue === manualOrdersTabs.NOTES.value && (
          <TabPanel
            sx={tabPanelStyles({ theme })}
            value={manualOrdersTabs.NOTES.value}
          >
            <ManualOrderNotes />
          </TabPanel>
        )}
      </TabContext>
    </Stack>
  );
};
