import { Box, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ManualOrderEntryApiResponse,
  manualOrderStatusFieldNames
} from '../manual-orders';
import { useUpdateManualOrdersMutation } from '../manual-orders-api-slice';
import {
  selectSelectedManualOrders,
  setIsSubmittingManualOrders
} from '../manual-orders-slice';
import { ManualOrderStatusSnackbar } from './ManualOrderStatusSnackbar';

type ManualOrderCompletedDateProps = {
  expirationDateRequired: (orderLineItemTypeLabel: string) => boolean;
  selectedContainsCompleted: boolean;
};

export const ManualOrderStatusCompletedDate = ({
  expirationDateRequired,
  selectedContainsCompleted
}: ManualOrderCompletedDateProps) => {
  const selectedManualOrders = useSelector(selectSelectedManualOrders);

  const dispatch = useDispatch();

  const [displayedCompletedDate, setDisplayedCompletedDate] = useState<
    null | string
  >(null);

  const [updateManualOrders, { isError, isSuccess, reset }] =
    useUpdateManualOrdersMutation();

  useEffect(() => {
    const moreThanOneSelected = (selectedManualOrders?.length ?? 0) > 1;
    const oneSelected = (selectedManualOrders?.length ?? 0) === 1;

    if (moreThanOneSelected && displayedCompletedDate !== null) {
      setDisplayedCompletedDate(null);
    } else if (oneSelected) {
      setDisplayedCompletedDate(selectedManualOrders![0].completedDate ?? null);
    } else if (displayedCompletedDate !== null) {
      setDisplayedCompletedDate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedManualOrders]);

  const getCompletedDateTooltipTitle = () => {
    if (selectedContainsCompleted) {
      return 'One or more selected manual orders are complete and their completed dates cannot be updated.';
    } else {
      return null;
    }
  };

  const handleCompletedDateChange = (value: Dayjs | null) => {
    dispatch(setIsSubmittingManualOrders(true));

    updateManualOrders({
      manualOrders: selectedManualOrders!.map(
        (manualOrder: ManualOrderEntryApiResponse) => ({
          ...manualOrder,
          completedDate: value!.toISOString(),
          expirationDate:
            expirationDateRequired(manualOrder.$orderLineItemType.label) &&
            manualOrder.expirationDate === undefined
              ? value!.add(1, 'year').toISOString()
              : manualOrder.expirationDate
        })
      )
    });
  };

  return (
    <>
      <ManualOrderStatusSnackbar
        fieldName={manualOrderStatusFieldNames.completedDate}
        isError={isError}
        isSuccess={isSuccess}
        reset={reset}
      />
      <Tooltip arrow followCursor title={getCompletedDateTooltipTitle()}>
        <Box>
          <DatePicker
            disabled={selectedContainsCompleted}
            label="Completed Date"
            onChange={handleCompletedDateChange}
            slotProps={{
              textField: {
                error: false,
                fullWidth: true,
                size: 'small'
              }
            }}
            value={dayjs(displayedCompletedDate)}
          />
        </Box>
      </Tooltip>
    </>
  );
};
