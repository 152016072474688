import type {
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel
} from '@mui/x-data-grid-pro';

import {
  DataGridPro,
  // eslint-disable-next-line import/named
  GridLogicOperator,
  // eslint-disable-next-line import/named
  getGridSingleSelectOperators,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import titleCase from 'string-title-case';

import type { CustomerDetails } from '../../common/types/customer';

import { TextCellWithOverflow } from '../../common/components/grid-cell/TextCellWithOverflow';
import { config } from '../../common/config/config';
import { useGetCustomersQuery } from '../../common/store/customers-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { ManualOrdersToolbar } from './ManualOrdersToolbar';
import {
  ManualOrderApiResponse,
  ManualOrderEntryApiResponse,
  ManualOrderTypes,
  manualOrderStatusFilterValueOptions
} from './manual-orders';
import { useGetOrderLineItemTypesQuery } from './manual-orders-api-slice';
import {
  selectCurrentPage,
  selectManualOrderFilters,
  updateFilter,
  updateFilterModel
} from './manual-orders-filter-slice';
import { ManualOrdersGridDefaults } from './manual-orders-grid-defaults';
import { setSelectedManualOrders } from './manual-orders-slice';

type ManualOrdersTableProps = {
  isLoading: boolean;
  manualOrdersResponse?: ManualOrderApiResponse | undefined;
};

export const ManualOrdersTable = ({
  isLoading,
  manualOrdersResponse
}: ManualOrdersTableProps) => {
  const filterOptions = useSelector(selectManualOrderFilters);
  const currentPage = useSelector(selectCurrentPage);
  const reseller = useSelector(selectReseller);

  const apiRef = useGridApiRef();

  const dispatch = useDispatch();

  const { customerNames } = useGetCustomersQuery(
    {
      reseller
    },
    {
      selectFromResult: ({ data }) => ({
        customerNames: data?.map(
          (customer: CustomerDetails) => customer.accountName
        )
      }),
      skip: !reseller
    }
  );

  const { data: orderLineItemTypeOptions } = useGetOrderLineItemTypesQuery({
    manualOrderTypes: [
      ManualOrderTypes.DNS_DOMAIN,
      ManualOrderTypes.WEB3_REGISTRATION
    ],
    reseller: reseller
  });

  // when data is updated, also update our state
  useEffect(() => {
    if (manualOrdersResponse) {
      dispatch(
        setSelectedManualOrders(
          Array.from(apiRef.current.getSelectedRows().values())
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualOrdersResponse]);

  const singleSelectFilterOperators = () =>
    getGridSingleSelectOperators().map(operator => ({
      ...operator,
      getValueAsString: (filterValues: string[]) =>
        filterValues
          .map((value: string) => {
            return titleCase(value?.replaceAll('_', ' '));
          })
          .join(', ')
    }));

  const columns = useMemo(
    (): GridColDef<ManualOrderEntryApiResponse>[] => [
      {
        field: 'domainName',
        filterable: false,
        flex: 1,
        headerName: 'Domain Name',
        renderCell: ({ row }) => (
          <TextCellWithOverflow cellValue={row.domainName} />
        )
      },
      {
        field: 'customerName',
        filterOperators: getGridSingleSelectOperators().filter(
          operator => operator.value === 'isAnyOf'
        ),
        flex: 0.75,
        headerName: 'Customer Name',
        renderCell: ({ row }) => (
          <TextCellWithOverflow cellValue={row.customerName} />
        ),
        type: 'singleSelect',
        valueOptions: customerNames
      },
      {
        field: 'orderLineItemType',
        filterOperators: singleSelectFilterOperators(),
        flex: 0.75,
        headerName: 'Service Type',
        renderCell: ({ row }) => (
          <TextCellWithOverflow cellValue={row.$orderLineItemType.label} />
        ),
        type: 'singleSelect',
        valueOptions: orderLineItemTypeOptions
      },
      {
        field: 'status',
        filterOperators: singleSelectFilterOperators(),
        flex: 0.75,
        headerName: 'Status',
        renderCell: ({ row }) => (
          <TextCellWithOverflow cellValue={row.$status.label} />
        ),
        type: 'singleSelect',
        valueOptions: manualOrderStatusFilterValueOptions
      },
      {
        field: 'createdDate',
        filterable: false,
        flex: 0.75,
        headerName: 'Created Date',
        renderCell: ({ row }) => (
          <TextCellWithOverflow cellValue={row.$createdDateFormatted} />
        ),
        valueGetter: ({ row }) => row.$createdDateFormatted
      },
      {
        field: 'completedDate',
        filterable: false,
        flex: 0.75,
        headerName: 'Completed Date',
        renderCell: ({ row }) => (
          <TextCellWithOverflow cellValue={row.$completedDateFormatted} />
        ),
        valueGetter: ({ row }) => row.$completedDateFormatted
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [manualOrdersResponse?.entries]
  );

  const handleFilterModelChange = (model: GridFilterModel) =>
    dispatch(updateFilterModel(model));

  const handlePaginationModelChange = (model: GridPaginationModel) =>
    dispatch(
      updateFilter({
        ...filterOptions,
        maxResults: model.pageSize,
        offset: model.pageSize * model.page
      })
    );

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length <= 0) {
      // user disabled sort, go back to default sort
      dispatch(
        updateFilter({
          ...filterOptions,
          dir: ManualOrdersGridDefaults.dir,
          sort: ManualOrdersGridDefaults.sort
        })
      );
    } else {
      dispatch(
        updateFilter({
          ...filterOptions,
          dir: model[0].sort,
          sort: model[0].field
        })
      );
    }
  };

  const handleSelectionChange = (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) =>
    dispatch(
      setSelectedManualOrders(
        // @ts-expect-error - TS2339: Property api does not exist on type GridCallbackDetails<any>: it definitely does
        Array.from(details.api.getSelectedRows().values())
      )
    );

  return (
    <DataGridPro
      apiRef={apiRef}
      checkboxSelection
      columns={columns}
      disableRowSelectionOnClick
      disableVirtualization={import.meta.env.MODE === 'test'}
      filterMode="server"
      initialState={{
        filter: {
          filterModel: {
            items: [
              {
                field: 'status',
                id: 1,
                operator: 'isAnyOf',
                value: ManualOrdersGridDefaults.statuses
              }
            ],
            logicOperator: GridLogicOperator.And
          }
        }
      }}
      loading={isLoading}
      onFilterModelChange={handleFilterModelChange}
      onPaginationModelChange={handlePaginationModelChange}
      onRowSelectionModelChange={handleSelectionChange}
      onSortModelChange={handleSortModelChange}
      pageSizeOptions={config.DEFAULT_TABLE_ROWS_PER_PAGE_OPTIONS}
      pagination
      paginationMode="server"
      paginationModel={{
        page: currentPage,
        pageSize: filterOptions.maxResults
      }}
      rowCount={manualOrdersResponse?.totalCount ?? 0}
      rows={manualOrdersResponse?.entries ?? []}
      slotProps={{
        filterPanel: {
          logicOperators: [GridLogicOperator.And],
          sx: {
            '& .MuiDataGrid-filterFormValueInput > div > div': { width: '100%' }
          }
        }
      }}
      slots={{ toolbar: ManualOrdersToolbar }}
      sortingMode="server"
    />
  );
};
