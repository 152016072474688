import { Stack } from '@mui/material';
import {
  // eslint-disable-next-line import/named
  GridToolbarContainer,
  // eslint-disable-next-line import/named
  GridToolbarFilterButton,
  // eslint-disable-next-line import/named
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';
import React from 'react';

import { ManualOrdersExportButton } from './ManualOrdersExportButton';

export const ManualOrdersToolbar = () => {
  return (
    <GridToolbarContainer
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <Stack direction="row" spacing={1}>
        <GridToolbarFilterButton />
        <ManualOrdersExportButton />
      </Stack>

      <GridToolbarQuickFilter
        debounceMs={1500}
        size="small"
        sx={{ mr: 1, mt: 1 }}
      />
    </GridToolbarContainer>
  );
};
